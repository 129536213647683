import Constants from '@/Common/Constants';
import PageHome from '@/Page/Home.vue';
import PageLocation from '@/Page/Location.vue';
import PageLogout from '@/Page/Logout.vue';
import PageMap from '@/Page/Map.vue';
import PageTransition from '@/Page/Transition.vue';
import Store from '@/Store';
import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';

// Override push and replace
// -----------------------------------------------------------------------------

const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function (location: string) {
	// @ts-ignore
	return originalPush.call(this, location).catch((err: Error) => err);
};

const originalReplace = VueRouter.prototype.replace;
VueRouter.prototype.replace = function (location: string) {
	// @ts-ignore
	return originalReplace.call(this, location).catch((err: Error) => err);
};

// Attach VueRouter
// -----------------------------------------------------------------------------

Vue.use(VueRouter);

// Define Routing
// -----------------------------------------------------------------------------

const unauthenticatedRoutes: string[] = ['PageHome', 'PageLogout'];

const routes: Array<RouteConfig> = [
	{
		component: PageHome,
		name: 'PageHome',
		path: '/',
	},
	{
		component: PageLocation,
		name: 'PageLocation',
		path: '/location',
	},
	{
		component: PageLogout,
		name: 'PageLogout',
		path: '/logout',
	},
	{
		component: PageMap,
		name: 'PageMap',
		path: '/map',
	},
	{
		component: PageTransition,
		name: 'PageTransition',
		path: '/transition',
	},
];


// Setup Router
// -----------------------------------------------------------------------------

const router = new VueRouter({
	base: process.env.BASE_URL,
	mode: 'history',
	routes: routes,
});


// Middleware hooks
// ---------------------------------------------------------------------------

// Paths are represented like: /desktop even if it's using hash mode
router.beforeEach((to, from, next) => {
	const isAuthenticated: boolean = Store.getters?.authenticated;
	const toName: string = to.name || '';

	// Authenticated to unacceptable page, redirect home
	next();
});

export default router;
