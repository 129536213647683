import State from './state';

/**
 * @class Getters
 * @package Store
 * @project Web Vue Template
 */
export default {
	/**
	 * Determine if we have passed the age gate
	 *
	 * @return boolean
	 */
	authenticated(state: typeof State): boolean {
		return !!state.isLoggedIn;
	},
};
