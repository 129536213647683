var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('section',{staticClass:"page-map"},[_c('GmapMap',{staticStyle:{"width":"100%","height":"100%"},attrs:{"center":{
			lat: 40.7589,
			lng: -73.9851
		},"options":{
			disableDefaultUi: true,
			fullscreenControl: true,
			mapTypeControl: false,
			rotateControl: false,
			scaleControl: false,
			streetViewControl: false,
			zoomControl: false,
		},"zoom":17}},_vm._l((_vm.markers),function(m,index){return _c('GmapMarker',{key:index,attrs:{"clickable":true,"draggable":false,"icon":{
				url: '/image/marker/marker-00.png',
				scaledSize: {
					width: 32,
					height: 48,
				},
			},"position":m.position},on:{"click":function($event){return _vm.Handle_OnClickMarker(m)}}})}),1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }