import * as THREE from 'three';
import Constants from '@/Common/Constants';

/**
 * @type interface
 */
export interface IStoreState {
	isLoggedIn: boolean;
	token?: string;
	user: any;
}

/**
 * @class State
 * @package Store
 * @project Web Vue Template
 */
const state: IStoreState = {
	isLoggedIn: false,
	token: undefined,
	user: undefined,
};

export default state;
