
	import { beforeDestroy, created, mounted } from '@/Common/Decorators';
	import { Component, Vue } from 'vue-property-decorator';
	import { Utility } from 'buck-ts';

	@Component
	export default class App extends Vue {
		/**
		 * @return void
		 */
		@created
		protected beforeRouteEnter(): void {
			this.$router.afterEach(() => {
				window.scrollTo(0, 0);
			});
		}

		/**
		 * @return void
		 */
		@mounted
		protected intervalExample(): void {
			// Utility.Interval.add(() => {
			// 	console.log('Example of using interval from the BUCK TypeScript package.');
			// }, 1000, 'example-interval');
		}
	}
