
	import { mounted } from '@/Common/Decorators';
	import { Component, Ref } from 'vue-property-decorator';
	import PageBase from '@/Page/Base.vue';

	/**
	 * @author Matt Kenefick <matt.kenefick@buck.co>
	 * @package Page
	 * @project Web Vue Template
	 */
	@Component({
		components: { },
	})
	export default class PageTransition extends PageBase {
		/**
		 * @return void
		 */
		@mounted
		public transitionToLocation(): void {
			console.log('Animate here...');

			// Transition to location
			setTimeout(() => {
				this.$router.push({
					name: 'PageLocation',
					query: this.$route.query,
				});
			}, 1000);
		}
	}
