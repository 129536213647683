
	import { mounted } from '@/Common/Decorators';
	import { Component, Ref } from 'vue-property-decorator';
	import Constants from '@/Common/Constants';
	import PageBase from '@/Page/Base.vue';

	/**
	 * @interface IMapLocation
	 */
	interface IMapLocation {
		name?: string;
		lat: number;
		lng: number;
	}

	/**
	 * @author Matt Kenefick <matt.kenefick@buck.co>
	 * @package Page
	 * @project Web Vue Template
	 */
	@Component({
		components: {
			ViewLocation: () => import('@/View/Location.vue'),
		},
	})
	export default class PageLocation extends PageBase {
		/**
		 * @return string
		 */
		public get locationName(): string {
			// Get query params from the url
			const url: URL = new URL(location.href);
			const params: URLSearchParams = new URLSearchParams(url.search);
			const lat: number = parseFloat(params.get('lat') || '0');
			const lng: number = parseFloat(params.get('lng') || '0');

			// Find name in Constants.LOCATIONS
			const locationRecord: IMapLocation | undefined = Constants.LOCATIONS.find((item: IMapLocation) => {
				return item.lat == lat && item.lng == lng;
			});

			// Debug
			console.log('locationRecord', locationRecord);

			return locationRecord?.name || '';
		}

		/**
		 * @return void
		 */
		@mounted
		public test(): void {
			// Na
		}
	}
