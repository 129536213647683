
	import { beforeDestroy, created, mounted } from '@/Common/Decorators';
	import { Component } from 'vue-property-decorator';
	import ViewBase from '@/View/Base.vue';

	/**
	 * @author Matt Kenefick <matt.kenefick@buck.co>
	 * @package Page
	 * @project Web Vue Template
	 */
	@Component
	export default class PageBase extends ViewBase {
		/**
		 * Bind associated methods with class
		 *
		 * @type string[]
		 */
		@created
		public bindKeyboard(): void {
			this.bind('Handle_OnKeyDown');
			this.bind('Handle_OnKeyUp');
		}

		/**
		 * @return void
		 */
		@mounted
		protected attachKeyboardEvents(): void {
			document.addEventListener('keydown', this.Handle_OnKeyDown);
			document.addEventListener('keyup', this.Handle_OnKeyUp);
		}

		/**
		 * @return void
		 */
		@beforeDestroy
		protected detachKeyboardEvents(): void {
			document.removeEventListener('keydown', this.Handle_OnKeyDown);
			document.removeEventListener('keyup', this.Handle_OnKeyUp);
		}

		// region: Event Handlers
		// ---------------------------------------------------------------------------

		/**
		 * @param KeyboardEvent e
		 * @return Promise<void>
		 */
		protected async Handle_OnKeyDown(e: KeyboardEvent): Promise<void> {
			// Not implemented
		}

		/**
		 * @param KeyboardEvent e
		 * @return Promise<void>
		 */
		protected async Handle_OnKeyUp(e: KeyboardEvent): Promise<void> {
			// Not implemented
		}

		// endregion: Event Handlers
	}
