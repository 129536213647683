import actions from './actions';
import getters from './getters';
import mutations from './mutations';
import state from './state';
import Constants from '@/Common/Constants';
import Persist from '@/Store/persist';
import Vue from 'vue';
import Vuex from 'vuex';
import VuexPersist from 'vuex-persist';

// Attach Vuex
// -----------------------------------------------------------------------------

Vue.use(Vuex);

// Setup Persist
// -----------------------------------------------------------------------------

const persistPlugin = new VuexPersist(
	Object.assign(Persist.options, {
		key: Constants.STORAGE_KEY,
		reducer: (state: IKeyValue) => ({
			isLoggedIn: state.isLoggedIn,
			token: state.token,
			user: state.user,
		}),
	}),
);
const plugins = [persistPlugin.plugin];

// Setup Store
// -----------------------------------------------------------------------------

const store = new Vuex.Store({
	/**
	 * Triggerable actions
	 * e.g. store.dispatch('increment');
	 */
	actions,

	/**
	 * Calculated values based off state
	 * e.g. store.getters.doubleCount;
	 */
	getters,

	/**
	 * Mutations are a way of modifying state indirectly
	 * e.g. store.commit('increment', 1);
	 */
	mutations,

	/**
	 * Vuex Plugin for persisting data across sessions
	 */
	plugins,

	/**
	 * Raw object of our current state
	 */
	state,
});

export default store;
