
	import { Component, Ref } from 'vue-property-decorator';
	import Constants from '@/Common/Constants';
	import PageBase from '@/Page/Base.vue';

	/**
	 * @interface IMarker
	 */
	interface IMarker {
		name: string;
		position: IPosition;
	}

	/**
	 * @interface IPosition
	 */
	interface IPosition {
		name?: string;
		lat: number;
		lng: number;
	}

	/**
	 * @author Matt Kenefick <matt.kenefick@buck.co>
	 * @package Page
	 * @project Web Vue Template
	 */
	@Component
	export default class PageMap extends PageBase {
		/**
		 * @return IMarker[]
		 */
		public get markers(): IMarker[] {
			let output: IMarker[] = [];

			Constants.LOCATIONS.forEach((location: IPosition) => {
				output.push({
					name: location.name || '',
					position: {
						lat: location.lat,
						lng: location.lng,
					},
				});
			});

			return output;
		}

		// region: Event Handlers
		// ---------------------------------------------------------------------------

		/**
		 * @param unknown e
		 * @return Promise<void>
		 */
		protected async Handle_OnClickMarker(e: IMarker): Promise<void> {
			const position: IPosition = e.position;

			// @ts-ignore
			this.$router.push({
				name: 'PageTransition',
				query: {
					lat: position.lat,
					lng: position.lng,
				},
			});
		}

		// endregion: Event Handlers
	}
