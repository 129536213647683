const usesPort: boolean = !!location.port;

/**
 * @class Constants
 * @package Common
 * @project Web Vue Template
 */
export default {
	/**
	 * @type string
	 */
	GOOGLE_MAPS_APIKEY: process.env.VUE_APP_GOOGLE_MAPS_APIKEY,

	/**
	 * Determine if this is being accessed via mobile device
	 *
	 * @type boolean
	 */
	IS_MOBILE: 'ontouchstart' in window,

	/**
	 * @type ...
	 */
	LOCATIONS: [
		{
			lat: 40.706086,
			lng: -73.996864,
			name: 'brooklyn-bridge',
		},
		{
			lat: 40.782865,
			lng: -73.965355,
			name: 'central-park',
		},
		{
			lat: 40.751551,
			lng: -73.975266,
			name: 'chrysler-building',
		},
		{
			lat: 40.748440,
			lng: -73.985664,
			name: 'empire-state-building',
		},
		{
			lat: 40.7832738,
			lng: -73.9591753,
			name: 'guggenheim',
		},
		{
			lat: 40.758895,
			lng: -73.978350,
			name: 'rockefeller-center',
		},
		{
			lat: 40.7589,
			lng: -73.9851,
			name: 'times-square',
		},
	],

	/**
	 * Where to redirect after a successful login.
	 *
	 * @type string
	 */
	LOGIN_REDIRECT_PATH: '/',

	/**
	 * @type string
	 */
	STORAGE_KEY: 'web-vue-template',
};
