
	import { mounted } from '@/Common/Decorators';
	import { Component } from 'vue-property-decorator';
	import PageBase from '@/Page/Base.vue';

	/**
	 * @author Matt Kenefick <matt.kenefick@buck.co>
	 * @package Page
	 * @project Web Vue Template
	 */
	@Component
	export default class PageLogout extends PageBase {
		/**
		 * @return void
		 */
		@mounted
		public logout(): void {
			// Logout
			this.$store.dispatch('logout');

			// Redirect home
			location.href = '/';
		}
	}
