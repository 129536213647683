import * as VueGoogleMaps from 'vue2-google-maps';
import './Theme';
import App from './App.vue';
import Constants from './Common/Constants';
import Router from './Router';
import Store from './Store';
import Vue from 'vue';

/**
 * Disable Vue settings
 */
Vue.config.devtools = false;
Vue.config.productionTip = false;
Vue.config.silent = true;

console.log(process.env.VUE_APP_GOOGLE_MAPS_APIKEY);

/**
 * Includes
 */
// @ts-ignore
Vue.use(VueGoogleMaps, {
	load: {
		key: process.env.VUE_APP_GOOGLE_MAPS_APIKEY,
	}
});

/**
 * Launch frontend application
 */
(async function () {
	new Vue({
		render: (h) => h(App),
		router: Router,
		store: Store,
	}).$mount('#app');
})();
