
	import { mounted } from '@/Common/Decorators';
	import { Component, Ref } from 'vue-property-decorator';
	import PageBase from '@/Page/Base.vue';

	/**
	 * @author Matt Kenefick <matt.kenefick@buck.co>
	 * @package Page
	 * @project Web Vue Template
	 */
	@Component
	export default class PageHome extends PageBase {
		/**
		 * @return void
		 */
		@mounted
		public transitionToMap(): void {
			console.log('Animate to map here...');

			// Transition to location
			setTimeout(() => {
				this.$router.push({
					name: 'PageMap',
				});
			}, 1000);
		}
	}
